<template>
  <div
      class="swiper islamicCentersMainCarousel w-full h-96 md:h-128"
  >
    <div class="swiper-wrapper">
      <div
          class="swiper-slide font-semibold text-2xl text-center w-full"
          v-for="banner in banners"
          :key="banner"
      >
        <NuxtImg
            :src="banner"
            alt=""
            class="w-full h-96 md:h-128 object-cover"
        />
      </div>
    </div>
  </div>
</template>

<script
    setup
    lang="ts"
>

import {Autoplay, EffectFade} from "swiper/modules";

const banners = ref([
  "/images/islamic_centers/islamic1.jpg",
  "/images/islamic_centers/islamic2.jpg",
  "/images/islamic_centers/islamic3.jpg",
])

const swiper = ref(null);
onMounted(() => {
  swiper.value = new useNuxtApp().$swiper('.islamicCentersMainCarousel', {
    modules: [Autoplay, EffectFade],
    direction: 'horizontal',
    loop: true,
    autoplay: {
      delay: 5000,
    },
    slidesPerView: 1,
    effect: 'fade',
    allowTouchMove: false,
  });
})

</script>

<style></style>
